import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
 

 <path d="M1450 1813 c-97 -32 -145 -95 -145 -194 0 -77 39 -149 93 -170 43
-17 25 -32 -30 -25 -36 4 -61 1 -77 -8 -20 -11 -30 -11 -56 0 -40 16 -143 11
-167 -10 -10 -9 -18 -28 -18 -44 0 -20 -8 -32 -30 -45 -35 -21 -38 -45 -10
-70 24 -20 46 -21 79 -3 20 11 30 11 55 1 17 -7 43 -12 58 -11 14 1 24 -2 22
-6 -3 -4 0 -8 5 -8 6 0 11 5 11 10 0 6 28 9 68 8 37 -1 72 0 77 2 6 1 38 2 72
1 55 -1 62 -3 66 -23 l4 -23 2 23 c1 20 5 22 39 18 26 -2 37 -8 36 -20 -1 -9
2 -13 7 -10 5 3 9 11 9 18 0 8 20 13 58 15 31 1 64 2 72 3 8 0 29 -3 45 -7 23
-5 36 -2 54 14 l24 21 33 -21 c39 -24 83 -19 102 11 12 20 7 50 -9 50 -14 0
-10 30 6 46 22 22 18 41 -11 60 -22 15 -32 16 -73 5 -36 -9 -55 -9 -77 -1 -16
7 -72 11 -124 10 -121 -3 -130 -2 -130 11 0 5 4 8 9 5 25 -16 101 115 101 174
0 37 -32 114 -58 140 -47 46 -138 71 -192 53z m143 -36 c52 -29 91 -96 91
-156 0 -140 -163 -232 -282 -159 -80 49 -111 151 -72 233 51 105 161 139 263
82z m-482 -427 c-3 -69 -21 -90 -73 -90 -32 0 -39 3 -36 18 2 11 14 18 35 20
36 4 49 27 39 68 -8 30 0 46 22 42 13 -2 16 -14 13 -58z m134 18 c8 -24 22
-23 30 2 6 20 47 37 75 31 8 -1 22 -9 31 -17 15 -13 17 -13 22 1 10 24 26 17
40 -17 l14 -33 17 33 c10 20 24 32 37 32 11 0 19 -3 17 -7 -16 -36 -69 -133
-73 -133 -2 0 -13 20 -24 45 -21 47 -33 60 -24 25 5 -17 0 -20 -29 -20 -22 0
-42 7 -52 19 -17 19 -17 20 11 23 23 4 25 6 10 12 -21 10 -47 -9 -47 -35 0
-24 33 -41 65 -33 30 7 34 -10 6 -26 -34 -18 -68 -12 -90 16 l-21 27 -21 -27
c-24 -31 -72 -35 -101 -9 -24 21 -24 84 0 105 35 32 95 24 107 -14z m383 20
c9 -9 12 -9 12 0 0 8 20 12 63 12 l62 -1 -27 -22 c-24 -20 -26 -27 -21 -69 5
-44 4 -48 -16 -48 -19 0 -21 6 -21 55 0 39 -4 55 -13 55 -10 0 -13 -14 -9 -55
4 -51 3 -55 -17 -55 -13 0 -21 6 -21 18 -1 16 -1 16 -14 0 -19 -26 -51 -22
-76 9 -23 30 -21 65 7 96 18 20 73 23 91 5z m235 -12 l21 -24 19 25 c16 20 27
23 61 21 52 -4 59 -32 8 -34 -39 -1 -55 -15 -50 -40 2 -13 14 -20 38 -22 68
-6 62 -42 -7 -42 -22 0 -38 8 -51 25 -13 17 -30 25 -50 25 -17 0 -38 8 -48 18
-17 16 -16 17 12 23 28 5 28 5 6 13 -45 15 -73 -42 -32 -64 11 -6 31 -8 45 -4
27 7 33 -4 13 -24 -17 -17 -68 -15 -90 5 -22 20 -24 71 -2 101 22 31 78 30
107 -2z"/>
<path d="M1435 1766 c-125 -56 -124 -230 2 -295 34 -18 95 -13 138 12 43 24
85 92 85 137 -1 38 -37 113 -51 104 -8 -4 -9 -3 -5 4 11 18 -54 52 -99 52 -22
-1 -53 -7 -70 -14z m73 -21 c-4 -21 -1 -25 18 -25 14 0 24 -6 24 -14 0 -23
-22 -31 -35 -13 -13 19 -25 22 -25 8 0 -5 -14 -23 -30 -40 -26 -26 -31 -37
-29 -74 2 -24 0 -48 -4 -54 -6 -10 13 -23 34 -23 8 0 5 47 -7 107 -4 19 3 24
52 40 31 9 59 23 61 31 3 7 -5 24 -17 37 -30 32 -17 46 14 16 15 -14 26 -39
31 -67 12 -82 -43 -161 -125 -179 -28 -6 -94 14 -72 22 6 3 12 9 12 14 0 6 -4
7 -10 4 -5 -3 -10 -1 -10 5 0 6 -4 9 -9 6 -5 -3 -14 14 -20 38 -11 46 1 103
27 119 7 5 10 12 7 18 -4 5 1 9 9 9 9 0 16 4 16 9 0 4 19 13 43 18 23 6 44 11
46 12 2 0 1 -10 -1 -24z m127 -137 c-1 -13 -4 -24 -5 -25 -1 -2 -3 -7 -4 -12
0 -5 -4 -13 -8 -17 -5 -4 -8 -17 -8 -28 0 -16 -3 -18 -9 -7 -6 9 -11 10 -15 2
-4 -6 -12 -11 -18 -11 -6 0 1 9 15 21 15 11 26 23 25 27 -1 4 2 22 6 40 9 36
25 44 21 10z"/>
<path d="M1168 1359 c-39 -22 -5 -79 34 -58 21 11 24 39 6 57 -14 14 -18 14
-40 1z"/>
<path d="M1566 1357 c-29 -21 -16 -51 22 -55 28 -3 32 0 32 20 0 22 -16 48
-30 48 -3 0 -14 -6 -24 -13z"/>
<path d="M1340 1199 c-19 -3 10 -6 65 -6 98 0 104 3 15 9 -25 2 -61 0 -80 -3z"/>
</g>
</svg>

      
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
